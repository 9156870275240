export default {
  v: {
    navMenu: {
      home: 'HOME',
      spot: 'EXCHANGE',
      c2c: 'OTC',
      otc: 'OTC',
      ac: 'WALLET',
      market: 'MARKET',
      cfd: 'CONTRACT',
      help: 'HELP CENTER',
      cfdSimulate: 'Simulate CFD',
    },
    /* 头部操作部分 */
    operation: {
      login: 'LOGIN',
      sign_up: 'SIGN UP',
      logout: 'LOGOUT',
      asset: 'ASSET',
      spot_asset: 'SPOT ACCOUNT',
      MyAsset: 'MY ASSET',
      wallet: 'OTC WALLET',
      Otc_Asset: 'OTC ACCOUNT',
      cfd_account: 'CFD ACCOUNT',
      Cfd_asset: 'CFD ASSET',
      Address: 'ADDRESS MANAGE',
      order: 'ORDER',
      exchange_order: 'SPOT ORDER',
      Current_Order: 'CURRENT ORDER',
      History_Order: 'HISTORY ORDER',
      otcorder: 'OTC ORDER',
      In_Order: 'ONGOING ORDER',
      Order_Detail: 'BILL DETAIL',
      Account_Safe: 'ACCOUNT SECURITY',
      In_Account: 'PAYMENT ACCOUNT',
      Invite_Friend: 'INVITE FRIENDS',
      Api_Manage: 'API MANAGE'
    },
    /* 底部 */
    foot: {
      new: 'NEW COINS',
      captionL: "Didn't find the digital assets you wanted in ",
      captionR: '?Feedback can be provided by recommending or filling out our currency exchange application form.',
      caption: 'If you didn’t find the digital assets you want, just fill in the application to give us feedback.',
      register: 'register a new coin',
      co: '©2018 All Rights Reserved',
      support: 'Support',
      blogroll: 'Blogroll',
      tips: 'Risk warning: trading due to the digital currency risk is bigger, vulnerable to the outside world, the currency and other factors, price fluctuation is bigger, we strongly recommend that you within itself can bear the risk of participating in digital currency trading!Investment has a risk, entering the market need to be careful.'
    }
  }
}