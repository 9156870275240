import HttpClient from '@spark/http-client'
import WebBootstrap from '@spark/web-bootstrap'
import Vue from 'vue'
import Utils from '@spark/utils'

function getUrl(system, applications) {
    let systemIndex = Utils.getObjectIndexInArray(applications, 'name', system)
    return applications[systemIndex].basePath
}
class ApiClient extends HttpClient {
    constructor() {
        super(Vue.prototype.$axios)
        this.applications = Vue.prototype.$casClient.applications
        this.webConfig = this.webConfig.bind(this)
        this.webOtherContent = this.webOtherContent.bind(this)
        this.newParities = this.newParities.bind(this)
        this.blogroll = this.blogroll.bind(this)
        this.article = this.article.bind(this)
        this.selfChosen = this.selfChosen.bind(this)
        this.allCoinDetails = this.allCoinDetails.bind(this)
        this.getMarketInfo = this.getMarketInfo.bind(this)
    }

    // 以下cms接口
    customizeArticle(data) {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_HOME, this.applications)
        return this.post(`${URL}/webOtherContent/query`, data)
    } //公告列表

    blogroll() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_HOME, this.applications)
        return this.get(`${URL}/linkConfig/list`)
    }
    webConfig() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_HOME, this.applications)
        return this.get(`${URL}/webConfig/query`)
    } // 获取首页信息
    webOtherContent() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_HOME, this.applications)
        return this.post(`${URL}/webOtherContent/query`)
    } // 获取首页信息
    newParities() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_SPOT, this.applications).split("/spot")[0]
        return this.get(`${URL}/silk/v2/legal/USDT/CNY`)
    } // 获取首页信息
    article() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_HOME, this.applications)
        return this.get(`${URL}/article/list`)
    } //公告列表

    selfChosen() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_SPOT, this.applications)
        return this.get(`${URL}/v3/favor/find`)
    } //查询自选缩略图

    allCoinDetails() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_SPOT, this.applications)
        return this.get(`${URL}/v3/coin/all`)
    } // 获取所有缩略图


    // common => 获取国家
    GetCountry() { // emex正式
        // const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        // // eslint-disable-next-line no-console
        // return this.post(`${URL}/register/findSupportCountry`)
        return this.post(`https://www.plccoin.com/prod-api/uc/register/findSupportCountry`)
    }

    // cas 验证 手机
    getCasCaptcha(tgt, type) {
        const URL = Vue.prototype.$casClient.casApiPath
        return this.get(`${URL}/cas/captcha/permission?type=${type}`, {
            headers: {
                tgt
            }
        })
    }
    // uc => 获取短信验证码Cid
    getPhoneCodeCid(data, check) {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        let _check = {}
        if (check) {
            _check = {
                headers: {
                    'check': check
                }
            }
        }
        return this.get(`${URL}/captcha/mm/phone?phone=${data}`, _check)
    }
    // uc => 获取邮箱验证码Cid
    getEmailCodeCid(data, check) {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        let _check = {}
        if (check) {
            _check = {
                headers: {
                    'check': check
                }
            }
        }
        return this.get(`${URL}/captcha/mm/email?email=${data}`, _check)
    }
    // uc => 获取极验证验证码
    getValidation() {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        return `${URL}/captcha/mm/gee`
    }
    cheakUseInviteCode(code) { // 检查用户邀请码
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        return this.get(`${URL}/register/check/inviteCode?inviteCode=${code}`)
    }

    cheakAgentInviteCode(code) { // 检查代理商邀请码
        const URL = WebBootstrap.$vue.$store.state.__commonInfo.agentUrl
        return this.get(`${URL}/agentApi/promotion/check/code?inviteCode=${code}`)
    }

    checkCodeLimit() { // 检查用户邀请码
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        return this.get(`${URL}/register/promotion/limit`)
    }

    tickets(tgt, check) {
        return this.get(`${Vue.prototype.$casClient.casApiPath}/cas/v1/tickets/${tgt}`, {
            headers: {
                'tgt': tgt,
                'check': check
            }
        })
    }

    // 手机注册
    phoneRegister(data, check) {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        return this.post(`${URL}/register/phone`, data, {
            headers: {
                'check': check
            }
        })
    }
    // 邮箱注册
    emailRegister(data, check) {
        const URL = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        return this.post(`${URL}/register/email`, data, {
            headers: {
                'check': check
            }
        })
    }

    // 取行情数据
    getMarketInfo() {
        // const uc = getUrl(process.env.VUE_APP_OTHER_SYSTEM_UC, this.applications)
        // const URL = uc.slice(0, -3)
        return this.get(`https://www.plccoin.com/prod-api/spotMarket/tiker/list`)
    }



    static install(Vue) {
        let _this = new ApiClient()
        HttpClient.install(Vue, '$ApiClient', _this)
    }


}

export default ApiClient
