export default {
  v: {
    navMenu: {
      home: 'página inicial',
      spot: 'negociação de moeda digital',
      c2c: 'negociação de moeda legal',
      otc: 'negociação de moeda legal',
      ac: 'gestão de ativos',
      market: 'mercado',
      cfd: 'negociação de contrato',
      help: 'centro de ajuda',
      cfdSimulate: 'contrato simulado',
      Api_Manage: 'gestão de API',
      cms: 'centro de ajuda'
    },
    /* 头部操作部分 */
    operation: {
      login: 'fazer login',
      sign_up: 'cadastrar agora',
      logout: 'sair',
      asset: 'ativos',
      spot_asset: 'conta de moeda digital',
      MyAsset: 'meus ativos',
      wallet: 'conta de moeda real',
      Otc_Asset: ' OTC ativo',
      cfd_account: 'conta de contrato',
      Cfd_asset: 'ativo de contrado',
      Address: 'configuração de endereço de saque',
      order: 'pedido',
      exchange_order: 'pedido de negociação de moeda digital',
      Current_Order: 'delegação atual',
      History_Order: 'histórico de delegaÇão',
      otcorder: 'pedido de negociação de moeda legal',
      In_Order: 'pedido em processando',
      All_Order: 'tudos pedidos',
      Order_Detail: 'detalhes de fatura',
      Account_Safe: 'segurança de conta',
      In_Account: 'conta de pagamento',
      Invite_Friend: 'onvite seus amigos !',
      Api_Manage: 'gestão de API',
      assetInfo: 'recarga & saque'
    },
    /* 底部 */
    foot: {
      new: 'nova negociação de ativos digitais',
      captionL: 'fica',
      captionR: 'Não encontrou o ativo digital que está procurando? Os feedbacks podem ser fornecidos recomendando ou preenchendo nosso formulário de solicitação da Coin',
      register: 'cadastrar nova negociação de ativos digitais',
      co: 'copyright',
      support: 'suporta',
      blogroll: 'link de amizade'
    }
  }
}
