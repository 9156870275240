<template>
  <nav :class="{logged: isLogged}">
    <ul class="navUl">
      <li v-for="nav in navList" :key="nav.name">
        <a :href="nav.url" :class="{active: activeNav(nav.url)}">{{ $t(`v.navMenu.${nav.name}`) }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
// 由于没有打包部署 所以使用了相对路径
import mixins from '../../../../../mixins'

export default {
  name: 'Nav',
  mixins: [mixins],
  props: {
    navList: {
      required: true,
      type: Array
    },
    isLogged: Boolean
  },
  methods: {
    activeNav(nav) {
      let { pathname, hash } = window.location
      // 如果 nav 路径里有 hash 则需要加入hash判断 否则只需要判断路径
      let index = nav.indexOf('//') + 2
      let domain = nav.substring(index)
      let hashIndex = domain.indexOf('#')
      let pathIndex = domain.indexOf('/')
      let navpath = domain.substring(pathIndex)
      if (hashIndex !== -1) {
        // nav链接存在hash 路径+hash判断
        return navpath === pathname + hash
      } else {
        return navpath === pathname.substring(0, pathname.length - 1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './custom-nav';
</style>
