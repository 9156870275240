import './style/common.less'
import CustomHeader from './components/custom-header'
import CustomFooter from './components/custom-footer'
import EmexBtn from './components/emex-btn'

const components = {
  CustomHeader,
  CustomFooter,
  EmexBtn
}

const Theme = {
  name: 'Theme',
  languages: {
    'en-US': require('./assets/lang/en').default,
    'zh-CN': require('./assets/lang/zh').default,
    'pt-BR': require('./assets/lang/br').default
  }
}

Theme.install = (Vue, options) => {
  Object.keys(components).forEach(key => {
    Vue.component(`Spark${key}`, components[key])
  })
}

export default Theme
