<template>
  <header v-if="commonInfo">
    <div class="wrapper clearfix">
      <a :href="commonInfo.home">
        <img class="logo" :src="commonInfo.logo" alt="logo" />
      </a>
      <custom-nav :navList="commonInfo.navs" />
      <operational-area
        :isLogged="isLogged"
        :assetList="assetList"
        :orderList="orderList"
        :memberList="memberList"
      />
    </div>
  </header>
</template>

<script>
import CustomNav from './components/custom-nav'
import OperationalArea from './components/operational-area'
export default {
  name: 'CustomHeader',
  components: {
    CustomNav,
    OperationalArea
  },
  data() {
    return {
      assetList:[],
      orderList:[],
      memberList:[]
    }
  },
  computed: {
    commonInfo() {
      return this.$store.state.__commonInfo
    },
    isLogged() {
      return this.$store.state.__isLogged
    }
  },
  created() {
    this.commonInfo.loginNav.forEach(element => {
      if (element.asset) {
        this.assetList = element.asset
      } else if (element.order) {
        this.orderList = element.order
      } else if (element.member) {
        this.memberList = element.member
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import './custom-header.less';
</style>
