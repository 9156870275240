import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from 'iview/dist/locale/en-US'
import zh from 'iview/dist/locale/zh-CN'
import br from 'iview/dist/locale/pt-BR'
import zhLanguage from '../common/lang/zh'
import enLanguage from '../common/lang/en'
import brLanguage from '../common/lang/br'
import WebTheme from '../assets/web-theme/dist/mobile'
Vue.use(VueI18n)
Vue.use(WebTheme)
Vue.locale = () => {}
const messages = {
  'en-US': Object.assign(en, enLanguage, WebTheme.languages['en-US']),
  'zh-CN': Object.assign(zh, zhLanguage, WebTheme.languages['zh-CN']),
  'pt-BR': Object.assign(br, brLanguage, WebTheme.languages['pt-BR'])
}
export default new VueI18n({
  // locale: localStorage.lang || 'en-US',
  locale:  'en-US', //暂时默认英文
  messages // set locale messages
})
