import moment from 'moment'
const install = function (Vue, options) {
  Vue.prototype.dateFormat = function dateFormat(tick) {
    return moment(tick * 1).format('YYYY-MM-DD HH:mm:ss')
  }
  Vue.prototype.dayFormat = function dayFormat(tick) { // 年月日
    return moment(tick * 1).format('YYYYMMDD')
  }
  Vue.prototype.timeFormat = function timeFormat(tick) { // 时分秒
    return moment(tick * 1).format('HH:mm:ss')
  }
  Vue.prototype.MDFormat = function dayFormat(tick) { // 月日
    return moment(tick * 1).format('MM月DD日')
  }
  Vue.prototype.mul = function mul(a, b) { // 乘法
    var c = 0
    var d = a.toString()
    var e = b.toString()
    try {
      c += d.split('.')[1].length
      // eslint-disable-next-line no-empty
    } catch (f) { }
    try {
      c += e.split('.')[1].length
      // eslint-disable-next-line no-empty
    } catch (f) { }
    return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
  }
  Vue.prototype.div = function div(a, b) { // 除法
    var c
    var d
    var e = 0
    var f = 0
    try {
      e = a
        .toString()
        .split('.')[1]
        .length
      // eslint-disable-next-line no-empty
    } catch (g) { }
    try {
      f = b
        .toString()
        .split('.')[1]
        .length
      // eslint-disable-next-line no-empty
    } catch (g) { }
    c = Number(a.toString().replace('.', ''))
    d = Number(b.toString().replace('.', ''))
    return (c / d) * (Math.pow(10, f - e))
  }
  Vue.prototype.add = function add(arg1, arg2) { // 加法
    var r1, r2, m, c
    try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
    c = Math.abs(r1 - r2)
    m = Math.pow(10, Math.max(r1, r2))
    if (c > 0) {
      var cm = Math.pow(10, c)
      if (r1 > r2) {
        arg1 = Number(arg1.toString().replace('.', ''))
        arg2 = Number(arg2.toString().replace('.', '')) * cm
      } else {
        arg1 = Number(arg1.toString().replace('.', '')) * cm
        arg2 = Number(arg2.toString().replace('.', ''))
      }
    } else {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', ''))
    }
    return (arg1 + arg2) / m
  }
  Vue.prototype.sub = function sub(arg1, arg2) { // 减法
    var r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    //last modify by deeka
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  }
  Vue.prototype.round = function round(v, e) {
    var t = 1
    for (; e > 0; t *= 10, e--)
      ;
    for (; e < 0; t /= 10, e++)
      ;
    return Math.round(v * t) / t
  }
  Vue.prototype.sinNum = function sinNum(arg) { // 解决科学记数法的显示问题
    var length; var title; var v;
    var r1 = String(arg).toLowerCase();
    var r2 = Number(arg)
    try {
      v = String(r1.split('e')[0]).indexOf('.')
    } catch (e) {
      v = 0
    }
    if (v !== -1) {
      length = r1.split('e')[0].split('.')[1].length
      title = r2.toFixed(Math.abs(r1.split('e')[1]) + length)
    } else {
      title = r2.toFixed(Math.abs(r1.split('e')[1]))
    }
    return title
  }

}

export default install
