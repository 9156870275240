export default {
  v: {
    navMenu: {
      home: '首页',
      spot: '币币交易',
      c2c: '法币交易',
      otc: '法币交易',
      ac: '资产管理',
      market: '行情',
      cfd: '合约交易',
      help: '帮助中心',
      cfdSimulate: '模拟合约',
    },
    /* 头部操作部分 */
    operation: {
      login: '登录',
      sign_up: '注册',
      logout: '退出登录',
      asset: '资产',
      spot_asset: '币币账户',
      MyAsset: '我的资产',
      wallet: '法币账户',
      Otc_Asset: 'OTC 资产',
      cfd_account: '合约账户',
      Cfd_asset: 'CFD资产',
      Address: '提币地址管理',
      order: '订单',
      exchange_order: '币币订单',
      Current_Order: '当前委托',
      History_Order: '历史委托',
      otcorder: '法币订单',
      In_Order: '进行中订单',
      Order_Detail: '账单明细',
      Account_Safe: '账户安全',
      In_Account: '收款账户',
      Invite_Friend: '邀请好友',
      Api_Manage: 'API管理'
    },
    /* 底部 */
    foot: {
      new: '新数字资产',
      captionL: '在',
      captionR: '没有找到您想要的数字资产？可通过推荐或填写我们的上币申请表进行反馈。',
      register: '注册新数字资产',
      co: '版权所有',
      support: '支持',
      blogroll: '友情链接',
      tips: '风险提示：由于数字货币交易风险较大，易受外界、币种等因素影响，价格波动较大，我们强烈建议您在自身能承受的风险范围内参与数字货币交易！投资有风险，入市需谨慎。'
    }
  }
}