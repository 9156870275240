export default [{
    path: '/',
    redirect: 'home',
  },
  {
    name: 'home',
    path: '/home',
    meta: {
      isStaticPage: true
    },
    component: () => import('@/views/home')
  },
  {
    name: 'market',
    path: '/market',
    meta: {
      isStaticPage: true
    },
    component: () => import('@/views/market/market.vue')
  },
  {
    name: 'download',
    path: '/download',
    meta: {
      isStaticPage: true
    },
    component: () => import('@/views/download')
  },
  {
    name: 'register',
    path: '/register',
    meta: {
      isStaticPage: true
    },
    component: () => import('@/views/register')
  },
  {
    path: 'aliValidator',
    name: '/aliValidator',
    meta: {
      isStaticPage: true
    },
    component: () => import('@/views/aliValidator')
  }
  // {
  //   name: 'article',
  //   path: '/article',
  //   meta: {
  //     isStaticPage: true
  //   },
  //   component: () => import('@/views/article')
  // }
]