/* 主题的公共逻辑 */

export default {
  computed: {
    // 获取当前主机地址+路径
    getOriginAndPath () {
      let { origin, pathname } = window.location
      return origin + pathname
    }
  }
}
