<template>
  <button class="emexBtn" @click="handleClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'EmexBtn',
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
@import './emex-btn';
</style>
