module.exports = {
  commonheader: {
    home: 'Home',
    market: 'Market'
  },
  commonfooter: {
    copyright: ' all rights reserved',
    plat: 'Digital Coin Trading Platform',
    download: 'Download Now',
    chinese:'简体中文',
    english:'English'
  },
  indexPage: {
    title: 'The/most/trustworthy',
    register: 'Register',
    registerPlaceholder: 'Please enter your phone number or email',
    tradeSystem: {
      spot: {
        name: 'Spot Trading',
        title: 'Exchange of all types of digital assets',
        t1: 'Pure in-memory high-performance matching engine',
        t2: 'Hundreds of trading pairs',
        t3: 'Extremely low transaction costs',
        t4: 'Excellent liquidity'
      },
      otc: {
        name: 'Fiat Trading',
        title: 'Supports exchange with fiat currencies',
        t1: 'Zero advertising fees',
        t2: 'Bidirectional appeal channels to protect both parties\' interests',
        t3: 'Custom + quick trading, dual-mode, multi-direction',
        t4: 'Merchant deposit system, high security'
      },
      cfd: {
        name: 'Contract Trading',
        title: 'The simplest and most user-friendly contract design',
        t1: 'Anti-liquidation mechanism to reduce trading risks',
        t2: 'No settlement date, averaging out losses',
        t3: 'Take profit, stop loss, multiple order types',
        t4: 'Mark price trading',
        t5: 'Prevention of malicious market manipulation'
      }
    },
    product: {
      title: 'Products and Services',
      desc: 'Providing secure and trustworthy digital asset trading and asset management services to millions of users worldwide, supporting the evolution of the blockchain economy and reshaping the new landscape of the industry ecosystem',
      t1: 'Global Presence',
      p1: 'Establishing localized trading service centers in multiple countries, integrating multiple business models into one',
      t2: 'Security System',
      p2: 'Professional distributed architecture and anti-DOS attack system, cold and hot wallet separation, multi-dimensional protection of user asset security',
      t3: 'Smooth Trading Experience',
      p3: 'Using a high-performance matching engine to ensure a smooth trading experience, capable of handling millions of orders per second, supporting more than 20 million concurrent users with smooth and stable operation',
      t4: 'Professional Team Support',
      p4: "Developed by a team of senior experts from the financial industry, every detail is meticulously crafted to ensure the platform's secure, stable, and efficient operation"
    },
    download: {
      title: 'Trade Anytime, Anywhere',
      desc: 'Offers an intuitive interface with real-time orders, charting tools, and full-service features available on iOS, Android, Mac, and more',
      btn: 'Download Now'
    },
    price: {
      th1: 'Pair',
      th2: 'Last Price',
      th3: '24H change',
      th4: '24H High',
      th5: '24H Low',
      th6: '24H Volume',
      th7: 'Operation',
      buy: 'Buy'
    }
  },
  service: {
    USDT: 'USDT ',
    BTC: 'BTC ',
    ETH: 'ETH ',
    CUSTOM: 'Custom Service',
    COIN: 'Coin',
    NewPrice: 'Lastest',
    ExchangeNum: 'Amount',
    Change: 'Change',
    high: 'Highest',
    low: 'Lowest',
    OpenPrice: 'Open Price',
    PriceTrend: 'Price Trend',
    Operate: 'Operate',
    Exchange: 'Exchange'
  },
  register: {
    come: 'Come to',
    register: 'Register',
    setPassword: 'Set Password',
    nextStep: 'Next Step',
    reg1: 'One account, all ',
    reg2: ' services',
    sendCode: 'Send code',
    resendcodeafter: "'s resend code",
    password: 'Login password',
    repassword: 'Confirm password',
    info: 'For the security of your property, be sure to keep your password, private key and other sensitive information.',
    agentcode1: 'Referral Code (Essential)',
    agentcode2: 'Referral Code (Optional)',
    agree1: ' I agree ',
    agree2: ' agreement as follows',
    clause1: 'User terms',
    clause2: 'Privacy policy',
    resendcode: 'Resend',
    usertip: 'Please Enter email',
    teltip: 'Enter tel',
    emailtip: 'Enter email',
    emailerr: 'Email format error,enter again',
    confirmpwdtip: 'Enter confirm password',
    confirmpwderr: 'Two passwords not same',
    agentcodetip: 'Please enter the recommendation code',
    singletip: 'Please check user agreement',
    pwdtip: 'Enter login password',
    telno: 'Tel',
    smscode: 'Sms verification code',
    smscodetip: 'Enter sms verification code',
    email: 'Email',
    emailcode: 'Email verification code',
    emailcodetip: 'Enter email verification code',
    tip: 'Tip',
    memberNot: 'Member does not exist',
    phoneRegister: 'Phone register',
    emailRegister: 'Email register',
    registerSucceed: 'Register succeed',
    membercode: 'Verification code error',
    pwdvalidate: 'Password too simple',
    create: 'Create',
    chooseLoaction: 'Please choose location',
    user: 'Phone/Email',
    agentcode: 'agent code (alternative)',
    goOn: 'continue',
    sure: 'confirm',
    cancel:'Cancel',
    notnull: 'Cannot be empty',
    waitforrecend:'Please wait for the cooldown time to end before sending',
    signsuccess:'Registration successful, download the app and log in for use.',
    errorcode:'Verification code error'
  },
  state: {
    Bhutan: 'Bhutan',
    EastSamoa: 'East Samoa',
    China: 'China',
    Taiwan: 'Taiwan',
    TheCentralAfricanRepublic: 'The Central African Republic',
    Denmark: 'Denmark',
    Ukraine: 'Ukraine',
    Uzbekistan: 'Uzbekistan',
    Uganda: 'Uganda',
    Uruguay: 'Uruguay',
    Chad: 'Chad',
    Yemen: 'Yemen',
    Armenia: 'Armenia',
    Israel: 'Israel',
    Iraq: 'Iraq',
    Iran: 'Iran',
    Belize: 'Belize',
    CapeVerde: 'Cape Verde',
    Russia: 'Russia',
    Bulgaria: 'Bulgaria',
    Croatia: 'Croatia',
    Guam: 'Guam',
    Gambia: 'Gambia',
    Iceland: 'Iceland',
    Guinea: 'Guinea',
    GuineaBissau: 'Guinea-Bissau',
    Liechtenstein: 'Liechtenstein',
    Congo: 'Congo',
    DemocraticRepublicoftheCongo: 'Democratic Republic of the Congo',
    Libya: 'Libya',
    Canada: 'Canada',
    Ghana: 'Ghana',
    Gabon: 'Gabon',
    Hungary: 'Hungary',
    SouthSudan: 'South Sudan',
    SouthAfrica: 'South Africa',
    Botswana: 'Botswana',
    Qatar: 'Qatar',
    Rwanda: 'Rwanda',
    Luxembourg: 'Luxembourg',
    India: 'India',
    Indonesia: 'Indonesia',
    Guatemala: 'Guatemala',
    Ecuador: 'Ecuador',
    Eritrea: 'Eritrea',
    FormerDemocraticYemen: 'Former Democratic Yemen',
    Syria: 'Syria',
    Cuba: 'Cuba',
    Kyrgyzstan: 'Kyrgyzstan',
    Djibouti: 'Djibouti',
    Kazakhstan: 'Kazakhstan',
    Colombia: 'Colombia',
    CostaRica: 'Costa Rica',
    Cameroon: 'Cameroon',
    Tuvalu: 'Tuvalu',
    Turkmenistan: 'Turkmenistan',
    Turkey: 'Turkey',
    SaintChristopherandNevis: 'Saint Christopher and Nevis',
    SaintLucia: 'Saint Lucia',
    SaoTomeandPrincipe: 'Sao Tome and Principe',
    StVincent: 'St. Vincent',
    StPierreandMiquelon: 'St. Pierre and Miquelon',
    ChristmasIsland: 'Christmas Island',
    SanMarino: 'San Marino',
    Guyana: 'Guyana',
    Tanzania: 'Tanzania',
    Egypt: 'Egypt',
    Ethiopia: 'Ethiopia',
    Kiribati: 'Kiribati',
    Tajikistan: 'Tajikistan',
    Senegal: 'Senegal',
    SierraLeone: 'Sierra Leone',
    Cyprus: 'Cyprus',
    Seychelles: 'Seychelles',
    Mexico: 'Mexico',
    Hawaii: 'Hawaii',
    Togo: 'Togo',
    DominicanRepublic: 'Dominican Republic',
    DominicanFederation: 'Dominican Federation',
    Austria: 'Austria',
    Venezuela: 'Venezuela',
    WakeIsland: 'Wake Island',
    Bangladesh: 'Bangladesh',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    AntiguaandBarbuda: 'Antigua and Barbuda',
    Andorra: 'Andorra',
    Micronesia: 'Micronesia',
    Nicaragua: 'Nicaragua',
    Nigeria: 'Nigeria',
    Niger: 'Niger',
    Nepal: 'Nepal',
    Bahamas: 'Bahamas',
    Pakistan: 'Pakistan',
    Barbados: 'Barbados',
    PapuaNewGuinea: 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Panama: 'Panama',
    Bahrain: 'Bahrain',
    Brazil: 'Brazil',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    Greece: 'Greece',
    Palau: 'Palau',
    CaymanIslands: 'Cayman Islands',
    Germany: 'Germany',
    Italy: 'Italy',
    SolomonIslands: 'Solomon Islands',
    TokelauIslands: 'Tokelau Islands',
    Latvia: 'Latvia',
    Norway: 'Norway',
    CzechRepublic: 'Czech Republic',
    Moldova: 'Moldova',
    Morocco: 'Morocco',
    Monaco: 'Monaco',
    Brunei: 'Brunei',
    Fiji: 'Fiji',
    Swaziland: 'Swaziland',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SriLanka: 'Sri Lanka',
    Singapore: 'Singapore',
    NewCaledonia: 'New Caledonia',
    newZealand: 'new Zealand',
    Japan: 'Japan',
    Chile: 'Chile',
    Korea: 'Korea',
    TheKingdomofCambodia: 'The Kingdom of Cambodia',
    Grenada: 'Grenada',
    Greenland: 'Greenland',
    Georgia: 'Georgia',
    Zanzibar: 'Zanzibar',
    Vatican: 'Vatican',
    Belgium: 'Belgium',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Tonga: 'Tonga',
    SaudiArabia: 'Saudi Arabia',
    France: 'France',
    FrenchGuiana: 'French Guiana',
    FrenchPolynesia: 'French Polynesia',
    FaroeIslands: 'Faroe Islands',
    Poland: 'Poland',
    Polynesia: 'Polynesia',
    PuertoRico: 'Puerto Rico',
    BosniaandHerzegovina: 'Bosnia and Herzegovina',
    Thailand: 'Thailand',
    Zimbabwe: 'Zimbabwe',
    Honduras: 'Honduras',
    Haiti: 'Haiti',
    Australia: 'Australia',
    Macao: 'Macao',
    Ireland: 'Ireland',
    Estnia: 'Estônia',
    Jamaica: 'Jamaica',
    TurksandCaicosIslands: 'Turks and Caicos Islands',
    TrinidadandTobago: 'Trinidad and Tobago',
    Bolivia: 'Bolivia',
    Nauru: 'Nauru',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Guadeloupe: 'Guadeloupe',
    WallisandFujinaIslands: 'Wallis and Fujina Islands',
    Vanuatu: 'Vanuatu',
    Reunion: 'Reunion',
    Belarus: 'Belarus',
    Bermuda: 'Bermuda',
    Gibraltar: 'Gibraltar',
    FalklandIslands: 'Falkland Islands',
    CorkIslands: 'Cork Islands',
    Kuwait: 'Kuwait',
    Comoros: 'Comoros',
    CotedIvoire: 'Cote d Ivoire',
    CocosIsland: 'Cocos Island',
    Peru: 'Peru',
    Tunisia: 'Tunisia',
    Lithuania: 'Lithuania',
    Somalia: 'Somalia',
    Jordan: 'Jordan',
    Namibia: 'Namibia',
    Niue: 'Niue',
    TheVirginIslands: 'The Virgin Islands',
    Myanmar: 'Myanmar',
    Romania: 'Romania',
    USA: 'America',
    Laos: 'Laos',
    Kenya: 'Kenya',
    Finland: 'Finland',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    UnitedKingdom: 'United Kingdom',
    Netherlands: 'Netherlands',
    NetherlandsAntilles: 'Netherlands Antilles',
    Mozambique: 'Mozambique',
    Lesotho: 'Lesotho',
    Philippines: 'Philippines',
    Salvador: 'Salvador',
    Portugal: 'Portugal',
    Mongolia: 'Mongolia',
    Montserrat: 'Montserrat',
    Spain: 'Spain',
    WesternSamoa: 'Western Samoa',
    NorfolkIsland: 'Norfolk Island',
    Benin: 'Benin',
    Zambia: 'Zambia',
    EquatorialGuinea: 'Equatorial Guinea',
    Vietnam: 'Vietnam',
    DiegoGarcia: 'Diego Garcia',
    Azerbaijan: 'Azerbaijan',
    Afghanistan: 'Afghanistan',
    Algeria: 'Algeria',
    Albania: 'Albania',
    UnitedArabEmirates: 'United Arab Emirates',
    Alaska: 'Alaska',
    Oman: 'Oman',
    Argentina: 'Argentina',
    Ascension: 'Ascension',
    Aruba: 'Aruba',
    SouthKorea: 'South Korea',
    HongKong: 'HongKong',
    Macedonia: 'Macedonia',
    Maldives: 'Maldives',
    Malvinas: 'Malvinas',
    Malawi: 'Malawi',
    Martinique: 'Martinique',
    Malaysia: 'Malaysia',
    Mayotte: 'Mayotte',
    MarshallIslands: 'Marshall Islands',
    Malta: 'Malta',
    Madagascar: 'Madagascar',
    Mali: 'Mali',
    MarianaIslands: 'Mariana Islands',
    Lebanon: 'Lebanon'
  }
}
