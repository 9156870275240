import Vue from 'vue'
import router from './router'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import WebBootstrap from '@spark/web-bootstrap'
import '@spark/web-bootstrap/dist/WebBootstrap.css'
import i18n from './config/i18n'
import './assets/coin/iconfont.css'
import './common/css/common.less'
import './assets/font/iconfont.js'
import base from '@/config/util.js'
import ApiClient from '@/api'
import {
    getGuid
} from '@/config/check'
Vue.use(iView)
Vue.use(base)
const moment = require('moment')
Vue.filter('dateFormats', function (tick) {
    return moment(tick).format('MM' + '/' + 'DD')
})
Vue.prototype.getGuid = getGuid

WebBootstrap.Bootstrap({
    currentSystem: 'AC',
    otherSystems: ['UC', 'SPOT'],
    ApiClient,
    router,
    atSameDomain: true,
    VUE_APP_CAS_BASE_PATH: process.env.VUE_APP_CAS_BASE_PATH,
    i18n
}).then(() => {
    WebBootstrap.$vue.$EventBus.on('_languageSwitched', lang => {
        WebBootstrap.$vue.$i18n.locale = lang
        window.localStorage.setItem('lang', WebBootstrap.$vue.$i18n.locale)
    })
    window.document.title = WebBootstrap.$vue.$store.state.__commonInfo.title;
    // document.getElementById('onlineService').src = WebBootstrap.$vue.$store.state.__commonInfo.onlineService
})